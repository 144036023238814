<template>
 <div class="z-10">
  <div class="w-full">
   <form
    @submit.prevent="updateCallQueue(this.id, msg)"
    class="shadow space-y-8 divide-y divide-gray-200 form_padding z-10"
    method="POST"
   >
    <div class="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
     <div>
      <h3 class="text-lg leading-6 font-medium text-gray-900">
       {{ this.title }}
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ $t("updateCallQueueForm") }}.</p>
     </div>
     <div class="space-y-6 sm:space-y-5">
      <div
       v-if="this.title === $t('callqueue.updateFormTitle')"
       class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
      >
       <label for="callqueue-number" class="block text-sm font-bold text-gray-700 sm:mt-px sm:pt-2">
        {{ $t("callqueueTable.number") }}
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
         v-model="this.form.number"
         required
         min="0"
         type="number"
         name="callqueue-number"
         id="callqueue-number"
         :class="`max-w-lg block w-full shadow-sm focus:ring-malachite-500 focus:border-malachite-light sm:max-w-xs sm:text-sm border-gray-300 rounded`"
        />
       </div>
      </div>
      <div
       class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
      >
       <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {{ $t("callqueueTable.name") }}
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
         v-model="this.form.name"
         required
         type="text"
         name="name"
         id="name"
         :class="`max-w-lg block w-full shadow-sm focus:ring-malachite-500 focus:border-malachite-light sm:max-w-xs sm:text-sm border-gray-300 rounded`"
        />
       </div>
      </div>
      <div
       v-if="this.title === $t('callqueue.updateFormTitle')"
       class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
      >
       <label
        for="callQueueGroupId"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
       >
        {{ $t("sidebar.specialNavigation.callqueuegroup") }}
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <select
         v-model="this.form.call_queue_group_id"
         id="callQueueGroupId"
         name="callQueueGroupId"
         :class="`max-w-lg block w-full shadow-sm focus:ring-malachite-500 focus:border-malachite-light sm:max-w-xs sm:text-sm border-gray-300 rounded`"
        >
         <option
          v-for="callQueueGroup in this.callQueuesGroups"
          :key="callQueueGroup.id"
          :value="callQueueGroup.id"
         >
          {{ callQueueGroup.name }}
         </option>
        </select>
       </div>
      </div>

      <div
       v-if="this.title === $t('callqueue.updateFormTitle')"
       class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
      >
       <label for="code" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Code
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
         v-model="this.form.code"
         type="text"
         name="code"
         id="code"
         class="max-w-lg block w-full shadow-sm focus:ring-malachite-500 focus:border-malachite-light sm:max-w-xs sm:text-sm border-gray-300 rounded"
        />
       </div>
      </div>
     </div>
    </div>

    <div class="pt-5">
     <div class="flex justify-end">
      <a
       href="javascript:void(0)"
       v-on:click="$emit('closeForm')"
       class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
       {{ $t("ticket.cancel") }}
      </a>
      <button
       type="submit"
       class="rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white"
      >
       {{ $t("ticket.send") }}
      </button>
     </div>
    </div>
   </form>
  </div>
 </div>
 <ConfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow"
 />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
 props: ["id", "title", "name", "callQueuesGroups"],
 components: { ConfirmationDialog },
 data() {
  return {
   account,
   form: {
    number: "",
    call_queue_group_id: null,
    name: "",
    code: "",
   },
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
  };
 },
 methods: {
  updateCallQueue(id, msg) {
   if (this.title === this.$t("callqueue.updateFormTitle")) {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueues/${id}`,
     params: {
      customerAccount: this.account,
      call_queue_group_id: this.form.call_queue_group_id,
      name: this.form.name,
      code: this.form.code,
      number: this.form.number,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios
     .request(options)
     .then((res) => {
      msg = this.$t("callQueueUpdatedSuccessfully");
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups/${id}`,
     params: {
      customerAccount: this.account,
      name: this.form.name,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios
     .request(options)
     .then((res) => {
      msg = this.$t("callQueueGroupUpdatedSuccessfully");
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
};
</script>

<style></style>
